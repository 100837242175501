import { Container, IconButton, ModalTitle } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion, type AnimationProps } from 'framer-motion';
import { useKeyPressEvent } from 'react-use';
import { forwardRef, useEffect } from 'react';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import { useModal } from '../../hooks';
import { MarketSelection } from './MarketSelection';
import { LanguageSelectionModal } from './LanguageSelectionModal';

const TRANSITION = {
  y: { type: 'spring', damping: 26, stiffness: 170 },
};

const DIALOG_ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: { y: '-100%' },
    visible: ({ height }) => ({ y: 0, height }),
  },
  transition: TRANSITION,
};

const CONTENT_ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ...TRANSITION,
        delay: 0.1,
      },
    },
  },
  transition: TRANSITION,
};

const MarketSwitcherModal = forwardRef<HTMLDivElement>(({ ...props }, ref) => {
  const { t } = useTranslation();
  const { isModalOpen: marketSelectionModalOpen, close } = useModal('marketSelection');

  useEffect(() => {
    if (ref && 'current' in ref && marketSelectionModalOpen) {
      lock(ref.current);
    }

    return () => {
      clearBodyLocks();
    };
  }, [marketSelectionModalOpen, ref]);

  useKeyPressEvent('Escape', close);

  return (
    <AnimatePresence mode="wait">
      {marketSelectionModalOpen && (
        <motion.div
          key="market-switcher-dialog"
          role="dialog"
          aria-modal="true"
          ref={ref}
          custom={{ height: '100%' }}
          title={t('marketSwitcher.countryTitle')}
          className="fixed inset-[0_0_0_auto] z-10 w-full overflow-y-auto bg-white lg:inset-[64px_0_0_auto] lg:z-[-1] lg:max-h-[calc(100dvh-64px)]"
          {...DIALOG_ANIMATION_PROPS}
          {...props}
        >
          <motion.div {...CONTENT_ANIMATION_PROPS}>
            <Container className="mt-5">
              <div className="mb-4 flex w-full items-center justify-between">
                <ModalTitle className="mx-auto">{t('marketSwitcher.countryTitle')}</ModalTitle>

                <IconButton
                  label={t('general.closeAction')}
                  variant="white"
                  icon="close"
                  size="s"
                  onClick={close}
                />
              </div>

              <MarketSelection />
            </Container>

            <LanguageSelectionModal />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export { MarketSwitcherModal };
