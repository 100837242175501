globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5f8bb304d6a2e2911a63f45e8fe8625a78193708"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import sentryCommonConfig from './sentry.common.config';

Sentry.init({
  ...sentryCommonConfig,

  // The following errors can be tolerated and shouldn’t be reported to Sentry:
  ignoreErrors: [
    // Legacy browsers:
    'Unexpected token',

    // ResizeObserver couldn't deliver all observations within a single animation frame, which is fine:
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    /Loading (?:CSS )?chunk \d+ failed/,
  ],
});
